import { css } from '@mui/material';
import classNames from 'classnames';
import Marquee from 'react-fast-marquee';

import { TemplateItem, TemplateItemSkeleton } from '~/src/components/business/TemplateItem';
import { LGSTemplate } from '~/src/types/lgs-template';

type MARQUEE_PROPS = React.ComponentProps<typeof Marquee>;

export interface TemplateMarqueeProps extends MARQUEE_PROPS {
  templates?: LGSTemplate[];
  onClickTemplate?: (template: LGSTemplate) => void;
}

export const TemplateMarquee = (props: TemplateMarqueeProps) => {
  return (
    <Marquee
      pauseOnHover
      autoFill
      { ...props }
      className={classNames(
        'TemplateMarquee',
        props.className
      )}
      css={css`
        min-height: 230px;
      `}
    >
      {
        props.templates && props.templates.map((template) => {
          return (
            <TemplateItem
              key={template.id}
              thumbnail={template.thumbnailUrl}
              css={css`
                display: block;
                width: 230px !important;
                height: 230px !important;
                margin-right: 20px;
              `}
              onCustomize={async () => {
                props.onClickTemplate && props.onClickTemplate(template);
              }}
            />
          );
        })
      }
      {
        props.templates === undefined &&
          <TemplateItemSkeleton
            css={css`
              display: block;
              width: 230px !important;
              height: 230px !important;
              margin-right: 20px;
            `}
          />
      }
    </Marquee>
  );
};
