import { css, styled, useTheme } from '@mui/material';
import classNames from 'classnames';
import Image from 'next/image';
import { HTMLAttributes, ReactNode } from 'react';


export interface BenefitCardProps extends Omit<HTMLAttributes<HTMLDivElement>, 'title'> {
  image?: string;
  title?: ReactNode;
  description?: ReactNode;
}

export const BenefitCard = ({ title, description, image, ...others }: BenefitCardProps) => {
  const theme = useTheme();

  return (
    <div
      { ...others }
      className={classNames(
        'BenefitCard',
        others.className
      )}
      css={css`
        border-radius: 10px;
        color: ${theme.app.colors['neutral-color-01']};
        background-color: ${theme.app.colors['neutral-color-10']};
        padding: 30px 32px 20px;
      `}
    >
      <div
        css={css`
          text-align: center;
          color: ${theme.app.colors['neutral-color-01']};
          margin-bottom: 12px;
        `}
      >
        { title }
      </div>
      <div
        className="BenefitCard__Description"
        css={css`
          text-align: center;
          color: ${theme.app.colors['neutral-color-05']};
          margin-bottom: 28px;
        `}
      >
        { description }
      </div>
      {
        image ?
          <Image
            src={image}
            alt={title?.toString() ?? ''}
            width={132}
            height={132}
            css={css`
              display: block;
              margin: auto;
            `}
          /> :
          <ImageContainer />
      }
    </div>
  );
};

const ImageContainer = styled('div')`
  width: 132px;
  height: 132px;
  background-color: #D9D9D9;
  margin: auto;
  margin-bottom: 20px;
`;
