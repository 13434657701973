import constate from 'constate';

interface UseTemplateBrowseProps {
  selectedCategoryId: string;
  setSelectedCategoryId: (selectedCategoryId: string) => void;
}

const useTemplateBrowse = (props: UseTemplateBrowseProps) => {
  const { selectedCategoryId, setSelectedCategoryId } = props;

  return {
    selectedCategoryId,
    setSelectedCategoryId,
  };
};

export const [TemplateBrowseProvider, TemplateBrowseContext] = constate(useTemplateBrowse);
