import styled from '@emotion/styled';
import { css, Typography } from '@mui/material';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';

import Feature01 from '@/assets/images/feature01@3x.png';
import Feature02 from '@/assets/images/feature02@3x.png';
import Feature03 from '@/assets/images/feature03@3x.png';
import { Nl2br } from '~/src/components/app/Nl2br/Nlbr';
import { ComponentProps } from '~/src/types/common';
import { nl2brForReact } from '~/src/utils/common';

import { BenefitCard } from '../../../components/BenefitCard/BenefitCard';

type BenefitsSectionBenefitsProps = ComponentProps

const BenefitContainer = styled('div')`
  display: flex;
  align-items: flex-start;
  column-gap: 36px;
`;

const BenefitItemContainer = styled('div')`
  flex: 1;
  align-self: stretch;
`;

export const BenefitsSectionBenefits = (props: BenefitsSectionBenefitsProps) => {
  const { t } = useTranslation('common');

  return (
    <BenefitContainer
      className={classNames(
        'BenefitsSection__Benefits',
        props.className
      )}
    >
      <BenefitItemContainer
        className="BenefitsSection__Benefit"
      >
        <BenefitCard
          title={
            <Typography
              component={'div'}
              variant="h4"
            >
              { nl2brForReact(t('common:benefits.inspiration.title')) }
            </Typography>
          }
          description={
            <Typography
              variant="body6"
            >
              <Nl2br>
                { t('common:benefits.inspiration.description') }
              </Nl2br>
            </Typography>
          }
          image={Feature01.src}
          css={css`
            height: 100%;
          `}
        />
      </BenefitItemContainer>
      <BenefitItemContainer
        className="BenefitsSection__Benefit"
      >
        <BenefitCard
          title={
            <Typography
              component={'div'}
              variant="h4"
            >
              <Nl2br>
                { t('common:benefits.personalization.title') }
              </Nl2br>
            </Typography>
          }
          description={
            <Typography
              variant="body6"
            >
              <Nl2br>
                { t('common:benefits.personalization.description') }
              </Nl2br>
            </Typography>
          }
          image={Feature02.src}
          css={css`
            height: 100%;
          `}
        />
      </BenefitItemContainer>
      <BenefitItemContainer
        className="BenefitsSection__Benefit"
      >
        <BenefitCard
          title={
            <Typography
              component={'div'}
              variant="h4"
            >
              <Nl2br>
                { t('common:benefits.download.title') }
              </Nl2br>
            </Typography>
          }
          description={
            <Typography
              component={'div'}
              variant="body6"
            >
              <Nl2br>
                { t('common:benefits.download.description') }
              </Nl2br>
            </Typography>
          }
          image={Feature03.src}
          css={css`
            height: 100%;
          `}
        />
      </BenefitItemContainer>
    </BenefitContainer>
  );
};
